<template>
<div class="CategoryNav">
	
	<li class="Wrap">
		
		<em>
			<el-popover width="400" placement="bottom-start" trigger="hover" v-if="categoryListOne.length > 0">
				<div class="CatgoryList">
					<template v-for="cat in categoryListOne">
						<li :class=" cat.Id == CategoryOne ? 'On':'' " :key="cat.Id" @click="SelectCat(1,cat)">{{cat.Name}}</li>
					</template>
				</div>
				
				<span slot="reference">
				  全部分类<i class="el-icon-arrow-right"></i>
				  {{CategoryOne == '' ? '全部商品':''}}
				</span>
			</el-popover>
			
			<span v-else>
			  全部分类
			</span>
			
		</em>
		
		<em v-if="CategoryOne != ''">
			<el-popover width="400" placement="bottom-start" trigger="hover" v-if="categoryListTwo.length > 0">
				<div class="CatgoryList">
					<template v-for="cat in categoryListTwo">
						<li :class=" cat.Id == CategoryTwo ? 'On':'' " :key="cat.Id" @click="SelectCat(2,cat)">{{cat.Name}}</li>
					</template>
				</div>
				
				<span slot="reference">
				  {{CategoryOneName}}<i class="el-icon-arrow-right"></i>
				</span>
			</el-popover>
			
			<span v-else>
			{{CategoryOneName}}
			</span>
			<i class="el-icon-arrow-right" v-if="CategoryTwo != ''"></i>
		</em>
		
		<em v-if="CategoryTwo != ''">
			<el-popover width="400" placement="bottom-start" trigger="hover" v-if="categoryListThree.length > 0">
				<div class="CatgoryList">
					<template v-for="cat in categoryListThree">
						<li :class=" cat.Id == CategoryThree ? 'On':'' " :key="cat.Id" @click="SelectCat(3,cat)">{{cat.Name}}</li>
					</template>
				</div>
				
				<span slot="reference">
				  {{CategoryTwoName}}<i class="el-icon-arrow-right"></i>
				</span>
			</el-popover>
			
			<span v-else>
			{{CategoryTwoName}}
			</span>
			<i class="el-icon-arrow-right" v-if="CategoryThree != ''"></i>
		</em>
		
		<em v-if="CategoryThree != ''">
			{{CategoryThreeName}}
		</em>
		
	</li>

</div>
</template>

<script>
	import {Popover} from 'element-ui'
	export default {
	  name: 'CategoryNav',
	  props: {
		categoryListOne:{ //一级分类列表
			type:Array,
			default:function(){
				return []
			}
		},
		categoryListTwo:{ //2级分类列表
			type:Array,
			default:function(){
				return []
			}
		},
		categoryListThree:{ //3级分类列表
			type:Array,
			default:function(){
				return []
			}
		},
		categorySelectedIds:{
			type:Array,
			default:function(){
				return []
			}
		}
	  },
	  data() {
	      return {
			  CategoryOne:'',//选中的一级分类ID
			  CategoryOneName:'',
			  CategoryTwo:'',
			  CategoryTwoName:'',
			  CategoryThree:'',
			  CategoryThreeName:'',
	      }
	    },
	  components: {
		  "el-popover":Popover,
	  },
	  created() {
	  },
	  methods:{
		  SelectCat(_level,_cat){
			  if(_level == 1){
				this.CategoryOne = _cat.Id
				this.CategoryOneName = _cat.Name
				this.CategoryTwo = ''
				this.CategoryThree = ''
				this.categorySelectedIds.splice(0,1,_cat.Id)
				this.categoryListTwo.splice(0,this.categoryListTwo.length)
				this.categoryListThree.splice(0,this.categoryListThree.length)
				this.GetCategoryList(2,_cat.Id)
			  }else if(_level == 2){
			    this.CategoryTwo = _cat.Id
			    this.CategoryTwoName= _cat.Name
				this.CategoryThree = ''
				this.categorySelectedIds.splice(1,1,_cat.Id)
				this.categoryListThree.splice(0,this.categoryListThree.length)
				this.GetCategoryList(3,_cat.Id)
			  }else if(_level == 3){
			    this.CategoryTwo = _cat.Id
			    this.CategoryTwoName= _cat.Name
				this.CategoryThree = ''
				this.categorySelectedIds.splice(2,1,_cat.Id)
				//this.GetCategoryList(3,_cat.Id)
			  }
		  },
		  GetCategoryList(_level,_fatherId){
		  		let data = {
		  			Service:'Goods',
		  			Class: 'GoodsCategory',
		  			Action: 'List',
		  			Level:_level,
					FatherId:_fatherId,
		  			Page:1,
		  			PageSize:200,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			if(_level == 1){
		  				this.CategoryListOne = res.Data.CategoryList
		  			}else if(_level == 2){
						this.categoryListTwo.splice(0,this.categoryListTwo.length)
						for(var i=0;i<res.Data.CategoryList.length;i++){
							this.categoryListTwo.push(res.Data.CategoryList[i])
						}
		  			}else if(_level == 3){
		  				this.categoryListThree.splice(0,this.categoryListThree.length)
		  				for(var i2=0;i2<res.Data.CategoryList.length;i2++){
		  					this.categoryListThree.push(res.Data.CategoryList[i2])
		  				}
		  			}
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  }
	  }
	}
</script>

<style scoped>

.CategoryNav{
	padding: 15px 0px;
}
.CategoryNav li em span{
	margin: 0px 5px;
}
.CatgoryList{
	display: flex;
	padding: 0px 0px 0px 20px;
	flex-wrap: wrap;
}
.CatgoryList li{
	width: 33.333%;
	padding: 5px 0px;
	line-height: 20px;
	cursor: pointer;
}
.CatgoryList li:hover,.CatgoryList li.On{
	color: rgba(228,0,0,1);
	font-size: 1.1rem;
}
</style>
