<template>
<div>
	<TopNav></TopNav>
	<Header></Header>
	
	<div class="BGWhite">
		
		<div class="Wrap">
			
			<div class="CategoryNav">
				
				<div class="Left" style="color: rgba(0,0,0,0.6);">
					
					<template v-if="CategoryOneSelected.Id != ''">
						<em @click="CategoryClick({Id:'',Name:'',Level:''})" style="cursor: pointer;"><span>全部商品</span> <i class="el-icon-arrow-right"></i></em>
					</template>
					
					<span>商品搜索</span> <i class="el-icon-arrow-right"></i> {{CategoryOneSelected.Name}} {{CategoryTwoSelected.Name}} {{CategoryThreeSelected.Name}} 
					
					<em style="margin-left: 10px;">
						<el-tag @close="KeywordsClose"
						  v-for="(kw,kwI) in KeywordsArr"
						  :key="kwI"
						  closable
						  size="medium"
						  :type="'info'">
						  {{kw}}
						</el-tag>
					</em>
					
				</div>
			
			</div>
			
			<div class="MainPanel">
				
				<!--分类-->
				<div class="Cates">
					<h5>商品分类</h5>
					
					<!--当前选中的一级分类-->
					<template v-if="CategoryOneSelected.Id != ''">
						<template v-for="(cateOne,cateOneI) in CategoryList">
					<div class="CateOne" :key="cateOneI" v-if="cateOne.Id == CategoryOneSelected.Id">
						<h4 :class="CategoryOneSelected.Id == cateOne.Id ? 'On':''">
							<em @click="CategoryPanelToggle(cateOne)">
								<i class="el-icon-plus" v-if="OpenCategoryOneId != cateOne.Id"></i>
								<i class="el-icon-minus" v-else></i>
							</em>
							<span @click="CategoryClick(cateOne)">
								{{cateOne.Name}}
							</span>
						</h4>
						<template v-if="OpenCategoryOneId == cateOne.Id">
							<div v-for="(cateTwo,cateTwoI) in cateOne.Children" :key="cateTwoI">
								<h6 :class="CategoryTwoSelected.Id == cateTwo.Id ? 'On':''"  @click="CategoryClick(cateTwo)">{{cateTwo.Name}}</h6>
								<ul>
									<li :class="CategoryThreeSelected.Id == cateThree.Id ? 'On':''" v-for="(cateThree,cateThreeI) in cateTwo.Children" :key="cateThreeI"  @click="CategoryClick(cateThree)">{{cateThree.Name}}</li>
								</ul>
							</div>
						</template>
						
					</div>
					</template>
					</template>
					
						<template v-for="(cateOne,cateOneI) in CategoryList">
						<div class="CateOne" :key="cateOneI" v-if="cateOne.Id != CategoryOneSelected.Id">
							<h4 :class="CategoryOneSelected.Id == cateOne.Id ? 'On':''">
								<em @click="CategoryPanelToggle(cateOne)">
									<i class="el-icon-plus" v-if="OpenCategoryOneId != cateOne.Id"></i>
									<i class="el-icon-minus" v-else></i>
								</em>
								<span @click="CategoryClick(cateOne)">{{cateOne.Name}}</span>
							</h4>
							<template v-if="OpenCategoryOneId == cateOne.Id">
								<div v-for="(cateTwo,cateTwoI) in cateOne.Children" :key="cateTwoI">
									<h6 :class="CategoryTwoSelected.Id == cateTwo.Id ? 'On':''"  @click="CategoryClick(cateTwo)">{{cateTwo.Name}}</h6>
									<ul>
										<li :class="CategoryThreeSelected.Id == cateThree.Id ? 'On':''" v-for="(cateThree,cateThreeI) in cateTwo.Children" :key="cateThreeI"  @click="CategoryClick(cateThree)">{{cateThree.Name}}</li>
									</ul>
								</div>
							</template>
							
						</div>
						</template>
				</div>
				
				<!--主体区-->
				<div class="Content">
					
					<div class="ChioceArea">	
							
						<div class="Item" v-if="BrandList.length > 0">
							<label>品牌</label>
							<ul>
								<li @click="SetBrand('')" :class="SelectedBrandId == '' ?  'On':''"><em>全部</em></li>
								<li v-for="item in BrandList" :key="item.Id" @click="SetBrand(item.Id)" :class="SelectedBrandId == item.Id ?  'On':''"><em>{{item.Name}}</em></li>
							</ul>
						</div>
						
						
						<div class="Item">
							<label>排序</label>
							<ul>
								<li :class="Sort == '' ? 'On':''" @click="SetSort('')"><em>综合</em></li>
								<li :class="Sort == 'price' ? 'On':''" @click="SetSort('price')"><em>价格低到高</em></li>
								<li :class="Sort == '-price' ? 'On':''"  @click="SetSort('-price')"><em>价格高到低</em></li>
							</ul>
							<div class="PriceRange">
								<i>价格区间</i>
								<i><el-input size="mini" v-model="PriceStart"></el-input></i>
								<i>-</i>
								<i><el-input size="mini" v-model="PriceEnd"></el-input></i>
								<i><el-button size="mini" @click="PriceCheck">确认</el-button></i>
							</div>
						</div>
						
					</div>
					
					<div class="ListLoading" v-if="IsGoodsListLoading">
						<span class="el-icon-loading"></span>正在玩命加载商品...
					</div>
					
					<div class="ListLoading" v-if="!IsGoodsListLoading && GoodsList.length == 0">
						<span class="el-icon-warning-outline"></span>当前条件下暂无商品
					</div>
					
					<div class="GoodsList">
						
						<div class="Goods" v-for="item in GoodsList" :key="item.Id">
							<span class="Thumb">
								<a :href="'/#/goods/detail/'+item.Id" target="_blank"><img :src="item.Thumb" /></a>
							</span>
							<span class="Title">
								<a :href="'/#/goods/detail/'+item.Id" target="_blank">{{item.Title}}</a>
							</span>
							<span class="Price">
								<em class="Val" style="color: rgba(23,134,65,1);">￥<i>{{item.Price}}</i></em>
								<div class="PriceRight">
									<em class="Cart"><el-button size="mini"><i class="el-icon-shopping-cart-2"></i></el-button></em>
								</div>
							</span>
						</div>
						
						<template v-if="GoodsList.length > 0 && GoodsList.length%4 > 0">
							<div class="Goods Empty" v-for="i in (4-GoodsList.length%4)" :key="i">
								
							</div>
						</template>
						
						<div class="Page">
							<el-pagination
							  background
							  layout="prev, pager, next"
							  :total="Total" :page-size="PageSize" :current-page.sync="Page" @current-change="handleCurrentChange">
							</el-pagination>
						</div>
					
					</div>
					
				</div>
				
			</div>
			
			
		</div>
	</div>
	
	
	<Footer></Footer>
</div>
</template>

<script>

	import {Pagination,Popover,Tag} from 'element-ui'
	import CategoryNav from '@/components/goods/CategoryNav.vue'
	
	export default {
	  name: 'GoodsList',
	  props: {
	  },
	  data() {
	      return {
			  KeywordsArr:[
			  ],//搜索关键词，用于展示特殊效果
			  Search:{
			  },
			  PopoverDisabled:false,
			  GoodsList:[],//商品列表
	        CategoryList:[],//一级分类列表
			SelectedSource:'',//选中的来源
			SelectedShopId:'',//选中店铺ID
			BrandList:[],//品牌列表,最多只显示
			SelectedBrandId:'',//选中品牌ID
			Page:1,//当前页码
			PageSize:52,//单页条数
			Keywords:'',//监控KEY WORDS
			Total:0,//总商品条数
			Sort:'',//排序(综合排序 权重模式,)
			PriceStart:0.00,//起始价格
			PriceEnd:10000000,//结束价格
			PriceStartFinal:0.00,//最终起始价格
			PriceEndFinal:10000000,//最终结束价格
			CategoryOneSelected:{ //当前选择的分类
				Id:'',
				Name:'',
				Level:'',//级别
			},
			CategoryTwoSelected:{ //当前选择的2分类
				Id:'',
				Name:'',
			},
			CategoryThreeSelected:{ //当前选择的2分类
				Id:'',
				Name:'',
			},
			OpenCategoryOneId:'',//当前需要打开的以及分类ID
			IsGoodsListLoading:false,
	      }
	    },
	  components: {
		  'el-pagination':Pagination,
		  "el-popover":Popover,
		  'el-tag':Tag,
		  CategoryNav
	  },
	  created() {
		  this.InitUrlQuery()
	  	this.GetCategoryList(1,'')
		this.ClearTopKeywords()
		this.GetGoodsList()
		this.GetBrandList()
	  },
	  computed: {
	  	TopKeywords() {
	  		return this.$store.getters.getTopKeywords;
	  	},
		RouterQuerys() {
			return this.$route.query;
		},
	  },
	  watch:{
		CategorySelect(v){
			this.Page = 1
			this.RouteQuery()
		},
		SelectedSource(v){
			this.Page = 1
			this.RouteQuery()
		},
		SelectedBrandId(v){
			this.Page = 1
			this.RouteQuery()
		},
		Sort(Order){
			this.Page = 1
			this.RouteQuery()
		},
		PriceStartFinal(Order){
			this.Page = 1
			this.RouteQuery()
		},
		PriceEndFinal(Order){
			this.Page = 1
			this.RouteQuery()
		},
		TopKeywords(v){
			if(v != ''){
				this.KeywordsArr[0] = v
				this.KeywordsArr = JSON.parse(JSON.stringify(this.KeywordsArr))
			}
			this.CategoryClick({Id:'',Name:'',Level:''}) //清空当前业务
			this.Page = 1
			this.RouteQuery()
		},
		RouterQuerys(_query){
			if(_query.c1 != undefined){
				this.initCategoryOneByUrl(_query.c1)
				this.GetGoodsList()
				this.GetBrandList()
			}else{
				this.OpenCategoryOneId = ''
				this.CategoryOneSelected = {
					Id:'',
					Name:'',
					Level:'',
				}
				this.GetGoodsList()
				this.GetBrandList()
			}
		}
	  },
	  methods:{
		  KeywordsClose(e){
			  this.KeywordsArr.splice( this.KeywordsArr.indexOf(e), 1);
			  this.$store.dispatch('setTopKeywords','')
			  this.GetGoodsList()
		  },
		InitUrlQuery(){
			let q = this.$route.query
			if(q.p != undefined && !isNaN(q.p)){
				this.Page = parseInt(q.p)
			}
			if(q.psize != undefined && !isNaN(q.psize)){
				this.PageSize = parseInt(q.psize)
			}
			if(q.bid){
				this.SelectedBrandId = q.bid
			}
			if(q.sid != undefined && !isNaN(q.sid)){
				this.SelectedShopId = q.sid
			}
			if(q.source != undefined && !isNaN(q.source)){
				this.SelectedSource = q.source
				if(this.SelectedSource == 'gm'){
					this.SelectedShopId = 'e4061562-af6a-4749-b0ac-f878e414d6ae'
				}
			}
			if(q.c1 != undefined){
				this.initCategoryOneByUrl(q.c1)
			}
			
			if(q.c2 != undefined){
				this.CategoryTwoSelected.Id = q.c2
			}
			if(q.c3 != undefined){
				this.CategoryThreeSelected.Id = q.c3
			}
			
			if(q.price_s != undefined && !isNaN(q.price_s)){
				this.PriceStart = parseFloat(q.price_s)
				this.PriceStartFinal = parseFloat(q.price_s)
			}
			if(q.price_e != undefined && !isNaN(q.price_e)){
				this.PriceEnd = parseFloat(q.price_e)
				this.PriceEndFinal = parseFloat(q.price_e)
			}
			if(q.keywords){
				this.KeywordsArr[0] = q.keywords
				this.$store.dispatch('setTopKeywords',q.keywords)
			}
		},
		initCategoryOneByUrl(_categoryId){
			
			//使用了顶部大导航，清理关键词
			this.$store.dispatch('setTopKeywords','')
			
			this.OpenCategoryOneId = _categoryId
			this.CategoryOneSelected = {
				Id:this.OpenCategoryOneId,
				Name:'',
				Level:1
			}
			if(this.OpenCategoryOneId == 'aceb8a8e-e060-4827-95fa-9cb2a7e0e180'){
				this.CategoryOneSelected.Name = '大家电'
			}else if(this.OpenCategoryOneId == '5baa37bd-5bb5-435b-aa90-f3085b2f82c9'){
				this.CategoryOneSelected.Name = '厨房电器/生活电器'
			}else if(this.OpenCategoryOneId == '5ee3a0f0-0201-4554-bc38-d8c1b47a06b3'){
				this.CategoryOneSelected.Name = '手机/数码/配件'
			}else if(this.OpenCategoryOneId == '7d9645f6-8e2f-4fc9-8f39-4e34abefc873'){
				this.CategoryOneSelected.Name = '电脑/办公/外设'
			}
			this.CategoryClick(this.CategoryOneSelected)
	
		},
		ClearTopKeywords(){
			if(this.$route.query.keywords == undefined){
				this.$store.dispatch('setTopKeywords','')
			}
		},
		SetShopId(_shopId){
			this.Page = 1
			this.SelectedShopId = _shopId  
		},
		SetSort(_v){
			this.Page = 1
			this.Sort = _v  
		},
		SetBrand(_v){
			this.Page = 1
			this.SelectedBrandId = _v
		},
		SetSource(_v){
			this.SelectedSource = _v
			
			this.SelectedShopId = ''
		
			
		},
		PriceStartChange(e){
			let newPrice = parseFloat(e)
			if(isNaN(newPrice)){
				this.PriceStart = 0.00
			}
			this.PriceStart = newPrice
		},
		PriceEndChange(e){
			let newPrice = parseFloat(e)
			if(isNaN(newPrice)){
				this.PriceStart = 0.00
			}
			this.PriceEnd = newPrice
		},
		PriceCheck(){
			this.Page = 1
			this.PriceStartFinal = this.PriceStart
			this.PriceEndFinal = this.PriceEnd
		},
		PopoverShow(){
			let that = this
			setTimeout(function(){
				that.PopoverDisabled = false
			},500)
		},
		CategoryPanelToggle(_cat){
			
			if(_cat.Level != 1){
				return
			}
			
			if(_cat.Id != this.CategoryOneSelected.Id){
				this.CategoryClick(_cat)
				return
			}
			
			//收起当前类目
			if(this.OpenCategoryOneId == _cat.Id){
				this.OpenCategoryOneId = ''
				return
			}
			
			//展开当前类目
			this.OpenCategoryOneId = _cat.Id
			
		},
		CategoryClick(_cat){
			
			if(_cat.Id == ''){
				
				this.CategoryOneSelected.Id = ''
				this.CategoryOneSelected.Name = ''
				
				this.CategoryTwoSelected.Id = ''
				this.CategoryTwoSelected.Name = ''
				
				this.CategoryThreeSelected.Id = ''
				this.CategoryThreeSelected.Name = ''
				
				this.GetBrandList()
				this.RouteQuery()
				
				return
			}
			
			if(_cat.Level == 1){
				
				this.CategoryOneSelected = JSON.parse(JSON.stringify(_cat))
			
				this.OpenCategoryOneId = this.CategoryOneSelected.Id
			
				if(_cat.Children == undefined || _cat.Children.length == 0){
					this.GetCategoryList(_cat.Level+1,_cat.Id)
				}
				this.CategoryTwoSelected.Id = ''
				this.CategoryTwoSelected.Name = ''
				
				this.CategoryThreeSelected.Id = ''
				this.CategoryThreeSelected.Name = ''
				
			}else if(_cat.Level == 2){
				
				this.CategoryOneSelected.Id = _cat.FatherId
				this.CategoryOneSelected.Name = _cat.FatherName
				
				this.CategoryTwoSelected = JSON.parse(JSON.stringify(_cat))
				
				this.CategoryThreeSelected.Id = ''
				this.CategoryThreeSelected.Name = ''
				
				
			}else if(_cat.Level == 3){
				
				this.CategoryThreeSelected = JSON.parse(JSON.stringify(_cat))
				this.CategoryTwoSelected.Id = _cat.FatherId
				this.CategoryTwoSelected.Name = _cat.FatherName
				
				
			}
			
			//加载品牌
			this.GetBrandList()
			this.RouteQuery()
			
			
		},
		handleCurrentChange(e){ //页码跳转遗失currentpage？
			this.Page = e
			document.body.scrollTop=document.documentElement.scrollTop=0
			this.GetGoodsList()
		},
		GetCategoryList(_level,_fatherId = ''){
				
				let data = {
					Service:'Goods',
					Class: 'GoodsCategory',
					Action: 'List',
					Level:_level,
					FatherId:_fatherId,
					Page:1,
					PageSize:200,
				}
				if(_level == 2){ //强制要求取出所有当前子分类下的三级分类
					data.ListAll = true
				}
				
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					
				
					if(res.ErrorId != 0){
						this.$message(res.Msg)
						return
					}
					
					if(res.Data.CategoryList == null){
						return
					}
					
					if(_level == 1){
						
						this.CategoryList = res.Data.CategoryList
						for(var j=0;j<this.CategoryList.length;j++){
							this.CategoryList[j].Children = []
						}
						
						//如果地址栏传入了分类id，则标记当前分类名
						if(this.CategoryOneSelected.Id != '' && this.CategoryOneSelected.Name == ''){
							
							for(var k=0;k<this.CategoryList.length;k++){
								if(this.CategoryOneSelected.Id == this.CategoryList[k].Id){
									this.CategoryOneSelected = this.CategoryList[k]
								}
							}
						}
						
						//判断是否需要初始化读取二级分类
						if(_level == 1 && this.OpenCategoryOneId != ''){
							this.GetCategoryList(2,this.OpenCategoryOneId)
						}
						
					}else if(_level == 2){
						
						for(var i=0;i<this.CategoryList.length;i++){
							
							if(this.CategoryList[i].Id == _fatherId){
								
								this.CategoryList[i].Children = res.Data.CategoryList
								
								for(var p=0;p<this.CategoryList[i].Children.length;p++){
									//设定二级分类
									if(this.CategoryList[i].Children[p].Id == this.CategoryTwoSelected.Id){
										this.CategoryTwoSelected = JSON.parse(JSON.stringify(this.CategoryList[i].Children[p]))
										//设定三级分类
										for(var m=0;m<this.CategoryList[i].Children[p].Children.length;m++){
											if(this.CategoryList[i].Children[p].Children[m].Id == this.CategoryThreeSelected.Id){
												this.CategoryThreeSelected = JSON.parse(JSON.stringify(this.CategoryList[i].Children[p].Children[m]))
											}
											
										}
									}
								}
								
							}
							
							
				
							
						}

						
						this.CategoryList = JSON.parse(JSON.stringify(this.CategoryList))
						
					}
				
					
				})
				.catch((res) => {
					this.$message('网络处理异常')
				})
		},
		GetBrandList(){
			
				this.BrandList = []
				let data = {
					Service:'Brand',
					Class: 'Brand',
					Action: 'List',
					Page:1,
					PageSize:11,
				}
				
				if(this.CategoryOneSelected.Level == 1){
					data.CategoryOne = this.CategoryOneSelected.Id
				}else if(this.CategoryOneSelected.Level == 2){
					data.CategoryTwo = this.CategoryOneSelected.Id
				}else if(this.CategoryOneSelected.Level == 3){
					data.CategoryThree = this.CategoryOneSelected.Id
				}else{
					data.Star = 10
				}
				
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					
					if(res.ErrorId != 0){
						this.$message(res.Msg)
						return
					}
					if(res.Data.BrandList == null || res.Data.BrandList == undefined){
						this.BrandList = []
						return
					}
					this.BrandList = res.Data.BrandList
					
				})
				.catch(function (response) {
					this.$message('网络请求错误')
				})
		},
		GetGoodsList(){
			this.GoodsList = []
			this.IsGoodsListLoading = true
				let data = {
					Service:'Goods',
					Class: 'Goods',
					Action: 'List',
					Page:this.Page,
					PageSize:this.PageSize,
					ShopId:this.SelectedShopId,
					PriceStart:this.PriceStartFinal,
					PriceEnd:this.PriceEndFinal,
					Sort:this.Sort,
					BrandId:this.SelectedBrandId,
					Title:this.$store.getters.getTopKeywords,
					CategoryOne :this.CategoryOneSelected.Id,
					CategoryTwo : this.CategoryTwoSelected.Id,
					CategoryThree : this.CategoryThreeSelected.Id,
					Status:60,
				}
	
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					
					this.IsGoodsListLoading = false
					if(res.ErrorId != 0){
						this.$message(res.Msg)
						return
					}
					
					if(res.Data.GoodsList == undefined || res.Data.GoodsList == null){
						return
					}
					
					this.GoodsList = res.Data.GoodsList
					this.Page = res.Data.CurrentPage
					this.Total = res.Data.Total
					
				})
				.catch(function (response) {
					this.IsGoodsListLoading = false
					this.$message('网络请求错误')
				})
		},
		PriceChange(){
			
		},
		RouteQuery(){
			this.Page = 1
			this.Total = 0
			document.body.scrollTop=document.documentElement.scrollTop=0
			this.GetGoodsList()
		},
	  }
	}
</script>

<style scoped>
	.CategoryNav{
		width: 100%;
		padding: 10px 0px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(0,0,0,0.05);
	}
	.CategoryNav .Left{
		flex: 1;
	}
	.CategoryNav .Right{
	}
	.CategoryNav .Right span{
		background-color: rgba(0,0,0,0.05);
		padding: 5px 10px;
		font-size: 12px;
		cursor: pointer;
	}
	.CatgoryList{
		display: flex;
		padding: 0px 0px 0px 20px;
		flex-wrap: wrap;
	}
	.CatgoryList li{
		width: 33.333%;
		padding: 5px 0px;
		line-height: 20px;
		cursor: pointer;
	}
	.CatgoryList li:hover,.CatgoryList li.On{
		color: rgba(23,134,65,1);
		font-size: 1.1rem;
	}
	
.ChioceArea{
	width: 100%;
	margin-top: 10px;
}
.ChioceArea .Item{
	display: flex;
	padding: 15px 0px 5px 0px;
	line-height: 25px;
	border-bottom: 1px solid rgba(0,0,0,0.06);
}
.ChioceArea .Item label{
	width: 80px;
	display: block;
	text-align: center;
	color: #999999;
	border-right: 1px solid rgba(0,0,0,0.04);
	margin-right: 20px;
}
.ChioceArea .Item ul{
	flex: 1;
	display: flex;
	flex-wrap: wrap;
}
.ChioceArea .Item ul li{
	width: 130px;
	margin-right: 5px;
	margin-bottom: 10px;
}
.ChioceArea .Item ul li em{
	display: block;
	padding: 0px 5px;
	color: #666666;
	text-align: center;
	cursor: pointer;
	overflow: hidden;
	text-overflow:ellipsis;
	white-space: nowrap;
}
.ChioceArea .Item ul li.On em,.ChioceArea .Item ul li:hover em{
	border: 1px solid rgba(23,134,65,1);
	color: rgba(23,134,65,1);
	line-height: 23px;
}
.ChioceArea .Item span{
	display: block;
	width: 100px;
	text-align: right;
}
.ChioceArea .Item .PriceRange{
	width: 350px;
	display: flex;
	justify-content: flex-end;
}
.ChioceArea .Item .PriceRange i{
	display: inline-block;
	font-style: normal;
	max-width: 90px;
	padding: 0px 5px;
}
.GoodsList{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
	justify-content: space-between;
}
.GoodsList	.Goods{
		width: 220px;
		border: 1px solid rgba(0,0,0,0.05);
		padding: 10px;
		margin-bottom: 20px;
		cursor: pointer;
		position: relative;
}
.GoodsList	.Goods:hover{
	border: 1px solid rgba(23,134,65,1);
}
.GoodsList	.Empty{
	border-color: rgba(0,0,0,0);
	cursor: default;
}
.GoodsList	.Empty:hover{
	border-color: rgba(0,0,0,0);
}
.GoodsList	.Goods	span{
			display: block;
			width: 100%;
}
.GoodsList	.Goods	span a{
	color: rgba(0,0,0,0.65);
}
.GoodsList	.Goods	span.Thumb{
	height: 220px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	
}
.GoodsList	.Goods	span img{
	width: 220px;
	height: 220px;
}

.GoodsList	.Goods	span.Title{
	height: 40px;
	line-height: 20px;
	margin: 5px 0px;
	overflow:hidden; 
	text-overflow:ellipsis;
	display:-webkit-box; 
	-webkit-box-orient:vertical;
	-webkit-line-clamp:2; 
}

.GoodsList	.Goods	span.Price{
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.GoodsList	.Goods	span .Val{
	flex: 1;
	font-size: 0.8rem;
	color: rgba(0,0,0,0.4);
}
.GoodsList	.Goods	span .Val	i{
	font-style: normal;
	font-size: 1.3rem;
	color: $yt-color-primary;
}

.Goods .Goods span .Price .PriceRight{
	
}

.GoodsList	.Goods	span .Cart{
	margin-right: 5px;
}
.GoodsList	.Goods	span .Do:hover{
	color: rgba(23,134,65,0.3);
}

.MainPanel{
	display: flex;
	width: 1280px;
}

.MainPanel .Cates{
	width: 260px;
	border-right: 1px solid rgba(0,0,0,0.05);
	margin-right: 20px;
}
.MainPanel .Cates h5{
	padding: 5px 10px;
	margin: 0px;
	color: #FFFFFF;
	font-size: 14px;
	background-color: rgba(23,134,65,1);
}
.MainPanel .Cates .CateOne{
	padding: 10px 10px 10px 0px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
}
.MainPanel .Cates .CateOne h4{
	margin: 0px;
	cursor: pointer;
}
.MainPanel .Cates .CateOne h4:hover,.MainPanel .Cates .CateOne h4.On{
	color: rgba(23,134,65,1);
}
.MainPanel .Cates .CateOne h4 em{
	border: 1px solid rgba(0,0,0,0.1);
	margin-right: 10px;
	font-size: 0.8rem;
	padding: 3px;
	color: rgba(0,0,0,0.4);
}
.MainPanel .Cates .CateOne div h6{
	margin: 0px;
	font-size: 14px;
	padding: 10px 0px;
	color: rgba(0,0,0,0.45);
	cursor: pointer;
}
.MainPanel .Cates .CateOne div h6:hover,.MainPanel .Cates .CateOne div h6.On{
	color: rgba(23,134,65,1);
}
.MainPanel .Cates .CateOne div ul{
	display: flex;
	flex-wrap: wrap;
}
.MainPanel .Cates .CateOne div ul li{
	width: 48%;
	height: 28px;
	line-height: 28px;
	overflow: hidden;
	font-size: 14px;
	color: rgba(0,0,0,0.65);
	cursor: pointer;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.MainPanel .Cates .CateOne div ul li:hover,.MainPanel .Cates .CateOne div ul li.On{
	color: rgba(23,134,65,1);
}
.MainPanel .Content{
	flex: 1;
}

.ListLoading{
	width: 100%;
	height: 350px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0,0,0,0.04);
	font-size: 16px;
	color: rgba(0,0,0,0.4);
}

</style>
